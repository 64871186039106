// FotoUploadSection.js in frontend/src/components
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faUser, faFileArrowUp, faFutbol, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import '../css/FotoUploadSection.css';
import Footer from './Footer.js';
import BspImage1 from '../assets/images/c1a0a377-4b99-43e9-9f4a-1a04f928054a.JPG'
import BspImage2 from '../assets/images/Julius_Maiwaldt_1-5.jpg'

const FotoUploadSection = () => {
    const { templateId } = useParams(); // Extract templateId from URL
    const [templateData, setTemplateData] = useState(null);

    const [fileName, setFileName] = useState('');
    const [fileSurname, setFileSurname] = useState('');
    const [files, setFiles] = useState(null);
    const [trikotnummer] = useState(' ');
    const [position, setPosition] = useState('');
    const [filePreview, setFilePreview] = useState(null);
    const [processedImagePreview, setProcessedImagePreview] = useState(null);
    const [cutoutImageFile, setCutoutImageFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingbtn, setIsLoadingbtn] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isChecked, setIsChecked] = useState(false); // For the first checkbox
    const [agreedToTerms, setAgreedToTerms] = useState(false);

    const apiBaseUrl = process.env.REACT_APP_API_URL;

    // Fetch template data once the component mounts
    useEffect(() => {
        fetch(`${apiBaseUrl}/api/templates/${templateId}`)
            .then(res => {
                if (!res.ok) {
                    throw new Error(`Failed to fetch template: ${res.statusText}`);
                }
                return res.json();
            })
            .then(data => {
                setTemplateData(data);
            })
            .catch(err => {
                console.error('Error fetching template data:', err);
            });
    }, [templateId, apiBaseUrl]);

    const handleFileChange = (event) => {
      const chosenFiles = event.target.files;
      setFiles(chosenFiles); // Retain the file for submission
      if (chosenFiles && chosenFiles.length > 0) {
          setFilePreview(URL.createObjectURL(chosenFiles[0])); // Display original image preview
          triggerCutout(chosenFiles[0]); // Trigger cutout processing immediately
      }
  };

  const triggerCutout = (imageFile) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("image", imageFile);

    fetch(`${apiBaseUrl}/cutout/${templateId}`, {
        method: 'POST',
        body: formData,
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Cutout failed: ${response.statusText}`);
            }
            return response.blob();
        })
        .then((blob) => {
            // Save the cutout image as a File for reuse
            setCutoutImageFile(new File([blob], "cutout.png", { type: "image/png" }));
            console.log("Cutout process completed successfully.");
        })
        .catch((err) => console.error("Error during cutout:", err));
};

    const handleNameChange = (event) => {
        setFileName(event.target.value);
    };

    const handleSurnameChange = (event) => {
        setFileSurname(event.target.value);
    }; 

   // const handleTrikotnummerChange = (event) => {
      //  setTrikotnummer(event.target.value);
    //};

    const handlePositionChange = (event) => {
        setPosition(event.target.value);
    };

    useEffect(() => {
      let blobUrl = null;
    
      const debounceFetch = debounce(() => {
        if (fileName && fileSurname && trikotnummer && position && cutoutImageFile) {
          setIsLoading(true); // Show spinner when request starts
          const formData = new FormData();
          formData.append("name", fileName);
          formData.append("surname", fileSurname);
          formData.append("position", position);
          formData.append("trikotnummer", trikotnummer);
          formData.append("cutout", cutoutImageFile);
    
          fetch(`${apiBaseUrl}/image-preview/${templateId}`, {
            method: 'POST',
            body: formData,
          })
            .then((res) => res.blob())
            .then((blob) => {
              blobUrl = URL.createObjectURL(blob);
              setProcessedImagePreview(blobUrl);
              setIsLoading(false); // Hide spinner when preview is ready
            })
            .catch((err) => {
              console.error("Error fetching preview:", err);
              setIsLoading(false); // Hide spinner on error
            });
        }
      }, 500);
    
      debounceFetch();
    
      return () => {
        if (blobUrl) {
          URL.revokeObjectURL(blobUrl);
        }
        debounceFetch.cancel();
      };
    }, [fileName, fileSurname, trikotnummer, position, cutoutImageFile, templateId, apiBaseUrl]);
  
    const isFormValid = () =>
      fileName &&
      fileSurname &&
      position &&
      files &&
      files.length > 0 &&
      cutoutImageFile &&
      isChecked &&
      agreedToTerms;

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission
        setIsLoadingbtn(true); 

        const formData = new FormData();
        formData.append("name", fileName);
        formData.append("surname", fileSurname);
        formData.append("position", position);
        formData.append("trikotnummer", trikotnummer);

        if (files && files.length > 0) {
            formData.append("image", files[0]);
        }

        // Submit to the endpoint that processes the image with the template data
        fetch(`${apiBaseUrl}/foto-upload/${templateId}`, {
            method: 'POST',
            body: formData,
        })
        .then((res) => {
          if (!res.ok) {
              throw new Error(`Server responded with status ${res.status}`);
          }
          setIsLoadingbtn(false); 
          setIsSubmitted(true);
          return res.json(); 
      })
        .then(data => {
            console.log('Processing response:', data);
        })
        .catch(error => console.error('Error uploading image:', error))
    };

    if (!templateData) {
        return (
            <div>
              <div>Loading template...</div>
            </div>
        );
    }

    return (
      <div>
        <div className='container'>
          <div className='rounded-box-horizontal'>
            <div className='instruction-box-horizontal'>
              <h1>Willkommen bei Team-Cards.de!</h1>
            </div>
            <div className='input-box-horizontal'>
              <h4>
                Hier kannst du deine Individualisierte Sammelkarte gestalten. 
                <br />
                Die Karten werden verteilt und unter den Teilnehmern getauscht und gesammelt.
                <br />
                <br />
                Als Inspiration findest du ein Beispiel hier:
              </h4>
              <div className="image-container">
                <img
                    src={BspImage1}
                    alt="Beispiel 1"
                    className="responsive-image"
                />
                <img
                    src={BspImage2}
                    alt="Beispiel 2"
                    className="responsive-image"
                />
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          {/*
          <div className="foto-upload-sidebar-box">
            <div className='foto-upload-sidebar'></div>
            <div className='fcircle-1'>
              <FontAwesomeIcon icon={faUser} className="icon" />
            </div>
            <div className='fcircle-2'>
              <FontAwesomeIcon icon={faFileArrowUp} className='icon' />
            </div>
            <div className='fcircle-3'>
              <FontAwesomeIcon icon={faFutbol} className="icon" />
            </div>
            <div className='fcircle-4'>
              <FontAwesomeIcon icon={faClipboardCheck} className="icon" />
            </div>
          </div>
          */}

          <div className='container'>
            <div className='rounded-box-horizontal'>
              <div className='instruction-box-horizontal'>
                <h1>Schritt 1</h1>
                <p className='p-white'>Lade hier das Foto hoch, das auf der Karte abgebildet werden soll</p>
              </div>
              <div className='input-box-horizontal'>
                <label>
                  <input type="file" name="file" id="files" multiple onChange={handleFileChange} />
                </label>
                <div className='image-placeholder'>
                  {filePreview ? (
                    <img src={filePreview} alt='preview' />
                  ) : (
                <p>Lade ein Bild hoch</p>
                )}
                </div>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='rounded-box-horizontal'>
              <div className='instruction-box-horizontal'>
                <h1>Schritt 2</h1>
                <p className='p-white'>Gib den Vor- und Nachnamen des Spielers ein</p>
              </div>
              <div className="input-box-horizontal">
                <div className='name-input'>
                    <label htmlFor="first-name">Name</label>
                    <input type="text" name="file-name" id="name" value={fileName} onChange={handleNameChange} />
                    <label htmlFor="surname">Nachname</label>
                    <input type="text" name="file-surname" id="surname" value={fileSurname} onChange={handleSurnameChange} />
                </div>
              </div>
            </div>
          </div>

          
          <div className='container'>
            <div className='rounded-box-horizontal'>
              <div className='instruction-box-horizontal'>
                <h1>Schritt 3</h1>
                <p className='p-white'>Wähle nun die Postition, die auf der Karte abgebildet werden soll</p>
              </div>

              {/*<div className="input-field-nummer">
                <label htmlFor="nummer">Trikotnummer</label>
                <input type="text" name="trikotnnummer" id="nummer" value={trikotnummer} onChange={handleTrikotnummerChange} />
              </div>*/}

              <div className="input-box-horizontal">
                <label>Wähle die Position des Spielers</label>
                <select value={position} onChange={handlePositionChange}>
                  <option value="" disabled>Auswählen</option>
                  <option value="Sturm">Stürmer</option>
                  <option value="Mittelfeld">Mittelfeld</option>
                  <option value="Abwehr">Abwehr</option>
                  <option value="Torwart">Torwart</option>
                </select>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='rounded-box-horizontal'>
              <div className='instruction-box-horizontal'>
                <h1>Überprüfe deine Eingaben</h1>
                <p className='p-white'>
                  Bitte überprüfe alle Angaben auf ihre Richtigkeit. Dies wird auf der Karte wie im Beispiel aufgedruckt.
                </p>
              </div>
              <div className='input-box-horizontal'>
                <div className='name-input'>
                <li className='p'>Name: {fileName} {fileSurname}</li>
                <li className='p'>Position: {position}</li>
                </div>
                <div className="pdf-preview">
                  {isLoading ? (
                    <div className="iframe-placeholder">
                      <div className="loading-spinner"></div>
                    </div>
                  ) : processedImagePreview ? (
                    <iframe
                      src={`${processedImagePreview}#zoom=page-fit`}
                      width="100%"
                      height="100%"
                      title="PDF Preview"
                      onLoad={() => setIsLoading(false)} // Hide spinner when iframe loads
                    ></iframe>
                  ) : (
                    <div className="iframe-placeholder">
                      <p>Vorschaubild</p>
                    </div>
                  )}
                </div>
                <label>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                />
                Ich bin berechtigt das Bild im Namen des Abgebildeten hochzuladen.
                </label>
                <label>
                  <input
                      type="checkbox"
                      checked={agreedToTerms}
                      onChange={(e) => setAgreedToTerms(e.target.checked)}
                  />
                  Ich stimme der Verarbeitung der hochgeladenen Bilder meines Kindes durch Team-Cards.de zur Erstellung individueller Sammelkarten gemäß der{' '}
                  <a
                      href="/datenschutzerklärung"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                      Datenschutzerklärung
                  </a>{' '}
                  zu.
                </label>
                <button
                    type="submit"
                    disabled={!isFormValid() || isLoadingbtn || isSubmitted} // Ensure button is only active when the form is valid
                    className={isSubmitted ? 'submitted' : isLoadingbtn ? 'loading' : ''}
                >
                    {isLoadingbtn ? (
                        <div className="loading-spinner"></div> // Show spinner while loading
                    ) : isSubmitted ? (
                        'Abgesendet' // Show "Abgesendet" after submission
                    ) : (
                        'Absenden' // Default button text
                    )}
                </button>
              </div>
            </div>
          </div>
        </form>
        <Footer />
      </div>
    );
};

export default FotoUploadSection;
